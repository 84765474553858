// assets
import {
  IconDashboard,
  IconSitemap,
  IconArticle,
  IconCoin,
  IconAdjustments,
  IconKey,
  IconGardenCart,
  IconUser,
  IconUserScan
} from '@tabler/icons-react';

// constant
const icons = { IconDashboard, IconSitemap, IconArticle, IconCoin, IconAdjustments, IconKey, IconGardenCart, IconUser, IconUserScan };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const panel = {
  id: 'panel',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: '总览',
      type: 'item',
      url: '/panel/dashboard',
      icon: icons.IconDashboard,
      breadcrumbs: false,
    },
    // {
    //   id: 'channel',
    //   title: '渠道',
    //   type: 'item',
    //   url: '/panel/channel',
    //   icon: icons.IconSitemap,
    //   breadcrumbs: false,
    //   isAdmin: true
    // },
    // {
    //   id: 'token',
    //   title: '凭证',
    //   type: 'item',
    //   url: '/panel/token',
    //   icon: icons.IconGardenCart,
    //   breadcrumbs: true
    // },
    {
      id: 'topic',
      title: '主题',
      type: 'item',
      url: '/panel/topic',
      icon: icons.IconKey,
      breadcrumbs: false,
      isAdmin: true
    },
    {
      id: 'task',
      title: '任务',
      type: 'item',
      url: '/panel/task',
      icon: icons.IconGardenCart,
      breadcrumbs: true
    },
    // {
    //   id: 'log',
    //   title: '日志',
    //   type: 'item',
    //   url: '/panel/log',
    //   icon: icons.IconArticle,
    //   breadcrumbs: false,
    //   isAdmin: true
    // },
    {
      id: 'user',
      title: '用户',
      type: 'item',
      url: '/panel/user',
      icon: icons.IconUser,
      breadcrumbs: false,
      isAdmin: true
    },
    {
      id: 'profile',
      title: '我的',
      type: 'item',
      url: '/panel/profile',
      icon: icons.IconUserScan,
      breadcrumbs: false,
      isAdmin: false
    },
    {
      id: 'setting',
      title: '设置',
      type: 'item',
      url: '/panel/setting',
      icon: icons.IconAdjustments,
      breadcrumbs: false,
      isAdmin: true
    }
  ]
};

export default panel;
