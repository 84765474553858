import { API } from 'utils/api';
import { useDispatch } from 'react-redux';
import { LOGIN } from 'store/actions';
import { useNavigate, useLocation } from 'react-router';
import { showSuccess } from 'utils/common';
import Cookies from 'js-cookie';


const getRootDomain = (hostname) => {
  const parts = hostname.split('.').filter(Boolean);
  return parts.length > 1 ? `${parts[parts.length - 2]}.${parts[parts.length - 1]}` : hostname;
};

const useLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const loginSuccess = () => {
    // // 子域升级根域token，删除避免两个不同的coo
    // const access_token = Cookies.get("access_token", { domain: window.location.hostname });
    // Cookies.remove("access_token", { domain: window.location.hostname });

    // Cookies.set('access_token', access_token, { domain: getRootDomain(window.location.hostname) });

    const params = new URLSearchParams(location.search);
    const redirectUri = params.get('redirect_uri');
    if (redirectUri) {
      // Check if redirectUri is an absolute URL or a relative path
      if (redirectUri.startsWith('http')) {
        window.location.href = redirectUri;
      } else {
        navigate(redirectUri);
      }
    } else {
      // Default route if redirect_uri isn't provided
      navigate('/panel');
    }
  }

  const login = async (username, password) => {
    try {
      const res = await API.post(`/api/user/login`, {
        username,
        password
      });
      const { success, message, data } = res.data;
      if (success) {
        localStorage.setItem('user', JSON.stringify(data));
        dispatch({ type: LOGIN, payload: data });
        loginSuccess();
      }
      return { success, message };
    } catch (err) {
      // 请求失败，设置错误信息
      return { success: false, message: '' };
    }
  };

  const githubLogin = async (code, state) => {
    try {
      const res = await API.get(`/api/oauth/github?code=${code}&state=${state}`);
      const { success, message, data } = res.data;
      if (success) {
        if (message === 'bind') {
          showSuccess('绑定成功！');
        } else {
          dispatch({ type: LOGIN, payload: data });
          localStorage.setItem('user', JSON.stringify(data));
          showSuccess('登录成功！');
          loginSuccess();
        }
      }
      return { success, message };
    } catch (err) {
      // 请求失败，设置错误信息
      return { success: false, message: '' };
    }
  };

  const larkLogin = async (code, state) => {
    try {
      const res = await API.get(`/api/oauth/lark?code=${code}&state=${state}`);
      const { success, message, data } = res.data;
      if (success) {
        if (message === 'bind') {
          showSuccess('绑定成功！');
        } else {
          dispatch({ type: LOGIN, payload: data });
          localStorage.setItem('user', JSON.stringify(data));
          showSuccess('登录成功！');
          loginSuccess();
        }
      }
      return { success, message };
    } catch (err) {
      // 请求失败，设置错误信息
      return { success: false, message: '' };
    }
  };

  const wechatLogin = async (code) => {
    try {
      const res = await API.get(`/api/oauth/wechat?code=${code}`);
      const { success, message, data } = res.data;
      if (success) {
        dispatch({ type: LOGIN, payload: data });
        localStorage.setItem('user', JSON.stringify(data));
        showSuccess('登录成功！');
        loginSuccess();
      }
      return { success, message };
    } catch (err) {
      // 请求失败，设置错误信息
      return { success: false, message: '' };
    }
  };

  const logout = async () => {
    await API.get('/api/user/logout');
    localStorage.removeItem('user');
    // Cookies.remove("access_token", { domain: getRootDomain(window.location.hostname) });
    dispatch({ type: LOGIN, payload: null });
    navigate('/');
  };

  return { login, logout, githubLogin, wechatLogin, larkLogin };
};

export default useLogin;
